import { render, staticRenderFns } from "./health-modal.vue?vue&type=template&id=dcb7e6fa&"
import script from "./health-modal.component.ts?vue&type=script&lang=ts&"
export * from "./health-modal.component.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/daniel/Desktop/business/EFM/02 RCP/code/rcp-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dcb7e6fa')) {
      api.createRecord('dcb7e6fa', component.options)
    } else {
      api.reload('dcb7e6fa', component.options)
    }
    module.hot.accept("./health-modal.vue?vue&type=template&id=dcb7e6fa&", function () {
      api.rerender('dcb7e6fa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/admin/health/health-modal.vue"
export default component.exports